:root {
  --toastify-color-progress-success: linear-gradient(
    to right,
    #9d2ddf 0%,
    #618bd6 51%,
    #9d2ddf 100%
  ) !important;
  --toastify-color-success: #7b43c6 !important;
  --toastify-color-info: #7b43c6 !important;
  --toastify-color-error: #f94d7c !important;
  --toastify-color-progress-error: linear-gradient(
    to right,
    #f94d7c 0%,
    #f88111 51%,
    #f94d7c 100%
  ) !important;

  --toastify-color-progress-info: linear-gradient(
    to right,
    #9d2ddf 0%,
    #618bd6 51%,
    #9d2ddf 100%
  ) !important;

  .Toastify__toast-body {
    text-align: center;
  }
}
