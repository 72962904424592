.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}

.btn-primary,
.btn-info,
.btn-danger,
.btn-success {
  text-align: center !important;
  text-transform: uppercase !important;
  transition: 0.5s !important;
  color: white !important;
  border-radius: 10px !important;
  border: none !important;
}

.btn-info,
.btn-danger,
.btn-success {
  background-size: 200% auto !important;
}

.btn-primary {
  background-image: linear-gradient(
    112deg,
    #9d2ddf 3.93%,
    #7b43c6 46.12%,
    #618bd6 95.94%
  );
}

.btn-info {
  background-image: linear-gradient(
    to right,
    #aaa7b8 0%,
    #d8d6e3 51%,
    #aaa7b8 100%
  );
  border-color: #aaa7b8 !important;
}

.btn-danger {
  background-image: linear-gradient(
    to right,
    #f94d7c 0%,
    #f88111 51%,
    #f94d7c 100%
  );
}

.btn-success {
  background-image: linear-gradient(
    to right,
    #00d084 0%,
    #83fae7 51%,
    #00d084 100%
  );
}

.btn-info:hover,
.btn-danger:hover,
.btn-success:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.lb-anywhere {
  line-break: anywhere;
}

mapbox-search-listbox > div > div:nth-child(2) {
  z-index: 999999;
}

.table tr td {
  background-color: transparent !important;
}

.input-group .btn {
  z-index: 1 !important;
}
