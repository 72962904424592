.card-view-height {
  @include media-breakpoint-between(md, lg) {
    height: 7.25rem;
  }
}
// checkbox customization support desk
.remove-checked-icon:checked[type='checkbox'] {
  background-image: none;
}

.open-tickets:checked {
  opacity: 0.5;
}

.due-tickets:checked {
  opacity: 0.3;
}

.unassigned-tickets:checked {
  opacity: 0.7;
}

.due-tickets-volume:checked {
  opacity: 0.3;
}

.unassigned-tickets-volume:checked {
  opacity: 0.15;
}

.bg-priority-medium {
  &:checked {
    border-color: #73d3fe !important;
    background-color: #73d3fe !important;
    .dark & {
      border-color: #229bd2 !important;
      background-color: #229bd2 !important;
    }
  }
}
.bg-priority-low {
  &:checked {
    border-color: #a9e4ff !important;
    background-color: #a9e4ff !important;
    .dark & {
      border-color: #195979 !important;
      background-color: #195979 !important;
    }
  }
}

.ticket-preview-avatar {
  width: 5.625rem;
  height: 5.625rem;
  border-radius: 50%;
}
.shadow-show-xl {
  @include media-breakpoint-up(xl) {
    box-shadow: var(--#{$prefix}box-shadow) !important;
  }
}
.hover-border-secondary:hover {
  border-color: var(--#{$prefix}secondary) !important;
}
.input-search-width {
  width: 13.75rem;
}
.rounded-xl-3 {
  @include media-breakpoint-up(xl) {
    border-radius: var(--#{$prefix}border-radius-lg);
  }
}

.contact-details-todo-list {
  height: 21rem;
  .offcanvas & {
    height: auto;
  }
}

.tab-contact-details,
.tab-tickets-status {
  .nav-link {
    &:not(.active):hover {
      border-color: transparent !important;
    }
    &.active {
      border-bottom: 2px solid var(--#{$prefix}primary) !important;
      background-color: var(--#{$prefix}card-bg) !important;
      outline: none !important;
      svg,
      span {
        color: var(--#{$prefix}primary) !important;
      }
      h6 {
        color: var(--#{$prefix}gray-900) !important;
      }
    }
  }
}
.top-customers-tab {
  width: 5rem;
  .nav-item {
    .nav-link {
      &.active {
        font-weight: $font-weight-semi-bold !important;
        &:after {
          top: 45%;
          right: -6px;
          bottom: unset;
          left: unset;
          border-width: 1px 1px 0 0;
        }
      }
    }
  }
  & + .tab-content {
    max-width: calc(100% - 5rem);
  }
}
