.gm-style-iw-chr {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
}

.gm-style-iw.gm-style-iw-c {
  padding-top: 14px !important;
}

.gm-ui-hover-effect {
  width: 30px !important;
  height: 42px !important;
}