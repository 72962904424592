//* -------------------------------------------------------------------------- */
/*                                 Theme                                      */
/* -------------------------------------------------------------------------- */
$enable-dark-mode: false;

@import "~bootstrap/scss/_functions";
@import "theme/functions";

@import "user-variables";
@import "theme/variables";
@import "~bootstrap/scss/variables";

@import "theme/maps";
@import "~bootstrap/scss/maps"; // ---new---

@import "~bootstrap/scss/mixins";
@import "theme/mixins";

@import "theme/_utilities";
@import "~bootstrap/scss/utilities";

@import "~bootstrap/scss/root";
@import "theme/root";

@import "ride-page";

/*-----------------------------------------------
|   Bootstrap Styles
-----------------------------------------------*/
@import "bootstrap";

/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
@import "theme/theme";

.bg-grey-light {
    background-color: 'rgba(0, 0, 0, 0.1)';
}