//*-----------------------------------------------
//|   Color System
//-----------------------------------------------*/

$white: #fff !default;
$gray-100: #f9fafd !default;
$gray-200: #edf2f9 !default;
$gray-300: #d8e2ef !default;
$gray-400: #b6c1d2 !default;
$gray-500: #9da9bb !default;
$gray-600: #748194 !default;
$gray-700: #5e6e82 !default;
$gray-800: #4d5969 !default;
$gray-900: #344050 !default;
$gray-1000: #232e3c !default;
$gray-1100: #0b1727 !default;
$black: #000 !default;

$grays: (
  "black": $black,
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
  "1000": $gray-1000,
  "1100": $gray-1100,
  "white": $white,
) !default;
// $body-bg: $gray-200 !default;

//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/
$blue: #9d2ddf !default;
$indigo: #618bd6 !default;
$purple: #7b43c6 !default;
$pink: #ff679b !default;
$red: #e63757 !default;
$orange: #fd7e14 !default;
$yellow: #f5803e !default;
$green: #00d27a !default;
$teal: #02a8b5 !default;
$cyan: #27bcfd !default;

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-500 !default;
$dark: $gray-1100 !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
) !default;

$theme-colors-soft: (
  "primary": tint-color($primary, 88%),
  "secondary": tint-color($secondary, 88%),
  "success": tint-color($success, 88%),
  "info": tint-color($info, 88%),
  "warning": tint-color($warning, 88%),
  "danger": tint-color($danger, 88%),
  "light": tint-color($light, 88%),
  "dark": tint-color($dark, 88%),
) !default;

$dark-grays: (
  "black": $white,
  "100": $gray-1100,
  "200": $gray-1000,
  "300": $gray-900,
  "400": $gray-800,
  "500": $gray-700,
  "600": $gray-600,
  "700": $gray-500,
  "800": $gray-400,
  "900": $gray-300,
  "1000": $gray-200,
  "1100": $gray-100,
  "white": $black,
) !default;

$theme-dark-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": rgba(map-get($dark-grays, "200"), 0.25),
  //TODO
  "dark": $light,
) !default;

$theme-dark-colors-soft: (
  "primary": shift-color($primary, 70%),
  "secondary": shift-color($secondary, 70%),
  "success": shift-color($success, 70%),
  "info": shift-color($info, 70%),
  "warning": shift-color($warning, 70%),
  "danger": shift-color($danger, 70%),
  "light": shift-color($light, 70%),
  "dark": shift-color($dark, 70%),
) !default;

//*-----------------------------------------------
//|   Brand colors
//-----------------------------------------------*/
$linkedin: #0077b5 !default;
$facebook: #3c5a99 !default;
$twitter: #1da1f2 !default;
$google-plus: #dd4b39 !default;
$github: #333333 !default;
$youtube: #ff0001 !default;

$brand-colors: (
  "facebook": $facebook,
  "google-plus": $google-plus,
  "twitter": $twitter,
  "linkedin": $linkedin,
  "youtube": $youtube,
  "github": $github,
) !default;

$icon-circle-colors: (
  "primary": $primary,
  "success": $success,
  "info": $info,
  "warning": $warning,
) !default;
